import React from "react"
import ReactPlayer from "react-player"
import { PageTitle } from "../../components/copy"
import SubHeading from "../../components/copy/subHeading"
import Gallery from "../../components/gallery"
import Layout from "../../components/layout"
import "./index.scss"

const NewsDetailScreen = ({ news }) => {
  console.log(news.video)
  return (
    <Layout>
      <div className="newsDetailScreen">
        <div className="newsDetailScreen-content">
          {news.date && <p className="newsDetail-date u-margin0">{news.date}</p>}
          <PageTitle
            className="newsDetailScreen-pageTitle"
            title={news.title}
          />
          {news.excerpt && <p className="newsDetail-excerpt u-margin0 u-marginTop12">{news.excerpt}</p>}
          <div className="newsDetailScreen-coverWrapper u-marginTop40">
            <img
              className="newsDetailScreen-coverImage"
              src={news.cover}
              alt={news.title}
            />
          </div>
          <div className="u-marginTop40">
            <div dangerouslySetInnerHTML={{ __html: news.content }} />
          </div>
        </div>
        <Gallery
          galleries={news.galleries}
        />
        {news.videos.length > 0 && (
          <SubHeading
            text="Video"
            className="u-marginTop64"
          />
        )}
        {news.videos.length > 0 && news.videos.map((video) => (
          <ReactPlayer
            key={video.url}
            style={{
              marginTop: 40,
              width: "100%",
              height: "100%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            url={video.url}
            controls={true}
          />
        ))}
      </div>
    </Layout>
  )
}

export default NewsDetailScreen
