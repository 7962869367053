import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import NewsDetailScreen from "../screens/newsDetail"

export default ({ data }) => (
  <>
    <SEO title={data.news.title} />
    <NewsDetailScreen
      news={data.news}
    />
  </>
)

export const query = graphql`
  query NewsDetail($id: String!) {
    news(id: {eq: $id}) {
      id
      title
      slug
      date
      cover
      excerpt
      content
      galleries {
        url
      }
      videos {
        url
      }
    }
  }
`
